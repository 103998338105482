/*
 * 업무구분: 고객
 * 화 면 명: MSPCM250M
 * 화면설명: 대출가망고객
 * 작 성 일: 2023.03.29
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="대출가망고객" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P" @on-scroll-bottom="fn_LoadingData()" :topButton="true">
    <template #frame-header-fixed>
      <!-- 탭 -->
      <mo-tab-box :defaultIdx="lv_index" @tab-change="fn_TabChangeHandler" ref="tabbox" class="ns-move-tab-box">
        <mo-tab-content :idx=1 label="보험계약대출" />  
        <mo-tab-content :idx=2 label="신용대출가망고객" />  
      </mo-tab-box>
      <!-- 전체체크 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @input="fn_CheckAll" :disabled="checkDisabled || isEmptyView" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{lv_TotalCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- 검색결과 -->
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData250M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData250M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData250M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData250M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData250M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData250M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData250M !== {}" @click="fn_OpenMSPCM116P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData250M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" direction="column" alignV="start" class="">
        <MSPCM252D ref="refCM252D" v-if="lv_index === 1" :cMLnPsbCustSVO="cMLnPsbCustSVO" :pOrgData250M="pOrgData250M" :userInfo="userInfo" :slctTabIdx="lv_index"
                                  @checkCount="fn_CheckSelectedItemCount"/>
        <MSPCM253D ref="refCM253D" v-if="lv_index === 2" :cMLnPsbCustSVO="cMLnPsbCustSVO" :pOrgData250M="pOrgData250M" :userInfo="userInfo" :slctTabIdx="lv_index"
                                  @checkCount="fn_CheckSelectedItemCount"/>
      </ur-box-container>
      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM252D from '@/ui/cm/MSPCM252D'
import MSPCM253D from '@/ui/cm/MSPCM253D'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 고객명 검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPCM250M',
  screenId: 'MSPCM250M',
  mixins: [Screen],
  components: {
    MSPCM252D,
    MSPCM253D,
    MSPCM116P,
  },
  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.fn_TabChangeHandler(this.lv_index);
  },

  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    if (this.lv_index === 1) this.$refs.refCM252D.fn_InitBottomSheet()
    else this.$refs.refCM253D.fn_InitBottomSheet()
  },

  computed: {
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData250M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data () {
    return {
      // 제어 변수
      isSearched: false,  // 검색 실행 여부
      isInit: false, // 검색 초기화 여부
      isFirst: true,  // 초회조회여부
      isMngr: false,  // 지점장 여부
      isLoading: false,
      isOneOrMoreCheck: false,  // 전체선택 여부
      lv_index: 1,  // 현재 선택된 탭 인덱스
      checkDisabled: false, // 체크박스 선택가능여부

      // 팝업 변수
      popup116: {}, // MSPCM116P 고객검색
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      pOrgData250M: {}, // 최근 조직 조회 데이터

      // 통신 변수
      cMLnPsbCustSVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 조회 서비스호출 Obj
        cnsltNo: '',          // 컨설턴트번호
        custNm: '',           // 고객명
        lnPrspCustScCd: '03'  // 조회구분
      },
      lv_TotalCnt: 0, // 누적 총 조회건수
      checkCount: 0,
      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}],
      stndKeyVal: 'start',
      pageRowCnt: '20', // 페이지 카운트
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData250M = {}  // 조직 조회 데이터
          this.isSearched = false // 검색이력
          this.isInit = true
          this.isFirst = true
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_TabChangeHandler
     * 설명       : 보험계약대출 / 신용대출가망고객 Tab Change
     ******************************************************************************/
    fn_TabChangeHandler (str) {
      let lv_Vm = this

      if (str === 1) {
        lv_Vm.lv_index = 1
        // 보험계약대출
        lv_Vm.lv_inputParam.lnPrspCustScCd = '03'
      } else {
        lv_Vm.lv_index = 2
        // 신용대출가망고객
        lv_Vm.lv_inputParam.lnPrspCustScCd = '01'
      }

      // 리스트 선택 상태 초기화
      if (this.isOneOrMoreCheck) this.isOneOrMoreCheck = false
      this.checkCount = 0

      // 초기화
      lv_Vm.pOrgData250M = {}  // 조직 조회 데이터
      lv_Vm.isSearched = false // 검색이력
      lv_Vm.isInit = true
      lv_Vm.isFirst = true
      // 리스트 재조회
      lv_Vm.fn_Submit()
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (!_.isEmpty(this.pOrgData250M) && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData250M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.cMLnPsbCustSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      if (this.isFirst) {
        this.pOrgData250M = {} // 최근 조직 조회 데이터
        this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
        this.lv_inputParam.custNm = ''
        this.isFirst = false
      }

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 보험계약대출 / 신용대출가망고객 조회 서비스 호출
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') {
        return
      } 
      // const trnstId = 'txTSSCM42S1'
      const trnstId = 'txTSSCM47S2'
      const auth = 'S'
      let lv_Vm = this
      let pParams = lv_Vm.lv_inputParam

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body) {
            let responseList = response.body.cMLnPsbCustSVO
            if (responseList.length > 0) {
              responseList.forEach((item) => {
                item.checked = false

                // 대출가망상품 항목이 있을 때 컬럼 세팅
                item.lnPrdt = ''
                if (item.lnPrdtYn1 === 'Y') {
                  item.lnPrdt = '전화'
                }
                if (item.lnPrdtYn2 === 'Y') {
                  if (!lv_Vm.$bizUtil.isEmpty(item.lnPrdt)) item.lnPrdt += ', 프리미엄'
                  else item.lnPrdt = '프리미엄'
                }
                if (item.lnPrdtYn3 === 'Y') {
                  if (!lv_Vm.$bizUtil.isEmpty(item.lnPrdt)) item.lnPrdt += ', 우량'
                  else item.lnPrdt = '우량'
                }

                lv_Vm.cMLnPsbCustSVO.push(item)
                lv_Vm.isEmptyView = false
              })
            } else {
              lv_Vm.isEmptyView = true
            }
            // 더보기 키값
            lv_Vm.stndKeyList = response.trnstComm.stndKeyList
            if (lv_Vm.stndKeyList === undefined || lv_Vm.stndKeyList[0] === undefined || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.$bizUtil.isEmpty(lv_Vm.stndKeyList[0].stndKeyVal)) {
              lv_Vm.stndKeyVal = 'end' // 다음페이지 데이터 존재안함(마지막 페이지)
            } else {
              lv_Vm.stndKeyVal = 'more' // 다음페이지 데이터 존재함
            }
            lv_Vm.lv_TotalCnt = (lv_Vm.cMLnPsbCustSVO === null) ? 0 : lv_Vm.cMLnPsbCustSVO.length
          } else {
            lv_Vm.isEmptyView = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })

      this.isLoading = false
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      // 더보기 시 전체체크 되어있으면 전체체크 해제
      if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
        this.isOneOrMoreCheck = false
      }

      if (!this.isLoading) {
        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('PART')
        
        this.fn_GetService()
      }
    },

    /*********************************************************
    * Function명: fn_CheckAll
    * 설명: 조회목록 전체 선택
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.cMLnPsbCustSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.cMLnPsbCustSVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.cMLnPsbCustSVO[n].checked = true
            } else {
              lv_Vm.cMLnPsbCustSVO[n].checked = false
            }
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     ******************************************************************************/
    fn_CheckSelectedItemCount (param) {
      let lv_Vm = this

      // 디비전의 체크 리스트 길이 동기화
      lv_Vm.checkCount = param.checkCount
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM116P
     * 설명       : 고객검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pIsMngr: this.isMngr,
          pPage: this.$options.screenId,
          pOrgData: this.pOrgData250M, // 최근 조직 조회 데이터
          pSearchKeyword: this.lv_inputParam.custNm // 고객명
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 파라미터 세팅
            this.lv_inputParam.custNm = pData.rtnData.searchKeyword
            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData250M = pData.rtnData.pOrgData
            if (!_.isEmpty(this.pOrgData250M) && this.isMngr === true) {
              this.lv_inputParam.cnsltNo  = this.pOrgData250M.cnslt.key // 선택한 컨설턴트 번호
            }
            // 검색 실행여부
            this.isSearched = true
            // 검색 초기화 여부
            this.isInit = pData.isInit
            if (this.isInit) this.isSearched = false
            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt
            
            // 재조회
            this.fn_Submit()

            this.$bottomModal.close(this.popup116)
          }
        }
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData250M = {}  // 조직 조회 데이터
                this.isSearched = false // 검색이력
                this.isInit = true
                this.isFirst = true

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
};
</script>
<style scoped>
</style>